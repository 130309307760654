import React, {useState} from "react";
import signInStyles from './SingInPage.module.css';
import {AppPaths, Texts} from "../../Utils/Texts";
import {SignIn, SignInAuthenticate, ResetPassword} from "../../ClipClient/UserToServer";
import {generatePath, useNavigate} from "react-router-dom";
import ClipInput from "../../Components/ClipInput/ClipInput";
import {getPictureUrl, validateEmail, validatePassword} from "../../Utils/Helpers";
import {createUser} from "../../features/user";
import {useDispatch} from "react-redux";
import ErrorModal from "../../Components/Modal/ErrorModal/ErrorModal";

const SignInForm = () => {

    const [email, setEmail] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [saveDetails, setSaveDetails] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorBody, setErrorBody] = useState('')

    const HideErrorModal = () =>
    {
        setShowErrorModal(false);
        setErrorBody('');
    }

    const handleSetEmail = (value) => {
        if (validateEmail(value))
            setEmailError(false);
        else
            setEmailError(true);
        setEmail(value);
    }

    const handleSetPassword = (value) => {
        if (validatePassword(value))
            setPasswordError(false);
        else
            setPasswordError(true);
        setPassword(value);
    }

    const handleSetSaveDetails = (event) => {
        // TODO
        setSaveDetails(event.target.checked);
    }

    const handleForgotPassword = () => {
        const emailSendTo = prompt('Please enter your email')
        console.log(emailSendTo)
        ResetPassword(emailSendTo).then(() => {
          // TODO : Change from alert to nicer message
          alert(`Email was send to : ${emailSendTo} with new password.`)
        })
    }

    const handleSignIn = () => {
        if (!emailError && !passwordError) {
            console.log(email, password);
            SignInAuthenticate(email, password).then(response => {
                if (response.status === 202) {
                    response.json().then(res => {
                        if (res.responseCode === 'Success') {
                            let token = res.data.authToken.token;
                            let expirationDate = res.data.authToken.expirationDate;
                            console.log(token, expirationDate);
                            SignIn(token).then(response => {
                                if (response.status === 202 || response.status === 200) {
                                    response.json().then(res => {
                                        if (res.responseCode === 'Success') {
                                            let name = res.data.name;
                                            let userId = res.data.userId;
                                            let pictureUri = res.data.picture;
                                            let picture = getPictureUrl(pictureUri);
                                            dispatch(createUser({name: name, token: token, userId: userId, picture:picture}));
                                            const path = generatePath(AppPaths.Home);
                                            navigate(path);
                                        }
                                        else {
                                            setErrorBody(res.message);
                                            setShowErrorModal(true);
                                        }
                                    })
                                }
                                else {
                                    setErrorBody(response.message);
                                    setShowErrorModal(true);
                                }
                            })
                        }
                        else {
                            setErrorBody(res.message);
                            setShowErrorModal(true);
                        }
                    });
                }
                else {
                    response.json().then(res => {
                        setErrorBody(res.message);
                        setShowErrorModal(true);
                    });
                }
            })
        }
    }

    const getDetailsElement = () => {
        return (
            <div className={signInStyles.Details}>
                <ClipInput
                    style={{width: '100%'}}
                    title={Texts.SignInPage.SIGN_IN_EMAIL_LABEL}
                    placeHolder={Texts.SignInPage.SIGN_IN_EMAIL_PLACEHOLDER}
                    value={email}
                    setValue={handleSetEmail}
                    error={emailError}
                    errorMsg={Texts.SignInPage.SIGN_IN_EMAIL_ERR_MSG}
                />
                <ClipInput
                    style={{width: '100%'}}
                    title={Texts.SignInPage.SIGN_IN_PASSWORD_LABEL}
                    placeHolder={Texts.SignInPage.SIGN_IN_PASSWORD_PLACEHOLDER}
                    value={password}
                    setValue={handleSetPassword}
                    error={passwordError}
                    errorMsg={Texts.SignInPage.SIGN_IN_PASSWORD_ERR_MSG}
                    type={"password"}
                />
            </div>
        );
    }

    const getRowForgotElement = () => {
      return (
          <div className={signInStyles.RowForgot}>
              <button onClick={handleForgotPassword} className={signInStyles.TextOnlyButton}>
                  {Texts.SignInPage.SIGN_IN_FORGOT_PASSWORD}
              </button>
          </div>
      );
    }

    const getActionsElement = () => {
      return (
          <div className={signInStyles.Actions}>
              <button onClick={handleSignIn} className={signInStyles.SignInButton}>
                  {Texts.SignInPage.SIGN_IN_BUTTON}
              </button>
          </div>
      );
    }

    return (
        <div className={signInStyles.Form}>
            {getDetailsElement()}
            {getRowForgotElement()}
            {getActionsElement()}
            <ErrorModal body={errorBody} showModal={showErrorModal} onClose={HideErrorModal}/>
        </div>
    );
};

export default SignInForm;