import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    value: {
        name: '',
        token: '',
        userId: '',
        picture:''
    }
};

export const userSlice = createSlice({
   name:'user',
   initialState,
   reducers: {
       createUser: (state, action) => {
           state.value = action.payload;
       }
   }
});

export const {createUser} = userSlice.actions;

export const selectName = (state) => state.user.value.name;
export const selectToken = (state) => state.user.value.token;
export const selectUserId = (state) => state.user.value.userId;
export const selectPicture = (state) => state.user.value.picture;

export default userSlice.reducer;