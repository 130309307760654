import React from "react";
import {Avatar} from "@mui/material";
import AvatarsStyle from './Avatars.module.css';

const NameAvatar = ({name, style}) => {
    const prefix = name.substring(0,2).toUpperCase();
    return (
        <Avatar sx={style&&style||{
            background: '#E1E4FF',
            border: '4px solid #FFFFFF',
            boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
            borderRadius: 100
        }
        }>
            {prefix}
        </Avatar>
    )
}

const ImageAvatar = ({img, style}) => {
    return (
        <Avatar sx={style&&style} alt={"user"} src={img} />
    )
}
export const Avatars = ({img1, name1, img2, name2}) => {
    const nameAvatarStyle = {width:96, height:96, margin: '0px -10px', zIndex: 1,background: '#E1E4FF', border: '4px solid #FFFFFF', boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)', borderRadius: 100}
    return (
        <div className={AvatarsStyle.AvatarContainer}>
            {img1? <ImageAvatar img={img1} style={{width:96, height:96}}/>:<NameAvatar name={name1} style={nameAvatarStyle}/>}
            {img2? <ImageAvatar img={img2} style={{width:96, height:96}}/>:<NameAvatar name={name2} style={nameAvatarStyle}/>}
        </div>
    );
};

export const SingleAvatar = ({img, name}) => {
    return (
        <div className={AvatarsStyle.AvatarContainer}>
            {img? <ImageAvatar img={img}/>:<NameAvatar name={name}/>}
        </div>
    );
}