import React from "react";
import signUpStyles from './SignUpPage.module.css'
import SignUpHeader from "./SignUpHeader";
import SignUpForm from "./SignUpForm";
import HaveAccountRow from "./HaveAccountRow";

const DetailsSection = () => {
    return (
        <div className={signUpStyles.DetailsSection}>
            <div className={signUpStyles.Content}>
                <SignUpHeader/>
                <SignUpForm/>
                <HaveAccountRow/>
            </div>
        </div>
    );
};

export default DetailsSection;