// Sign in page texts
const SignInPage = {
    SIGN_IN_TITLE: 'Sign in to your account',
    SIGN_IN_SUPPORT_TEXT: 'Welcome back! Please enter your details',
    SIGN_IN_EMAIL_LABEL: 'Email',
    SIGN_IN_EMAIL_PLACEHOLDER: 'Enter your email',
    SIGN_IN_PASSWORD_LABEL: 'Password',
    SIGN_IN_PASSWORD_PLACEHOLDER: '*****',
    SIGN_IN_DONT_HAVE_ACCOUNT: 'Don\'t have an account yet?',
    SIGN_IN_FORGOT_PASSWORD: 'Forgot password?',
    SIGN_IN_BUTTON: 'Sign in',
    SIGN_IN_GO_TO_SIGN_UP: 'Sign up',
    SIGN_IN_EMAIL_ERR_MSG: 'Wrong email format',
    SIGN_IN_PASSWORD_ERR_MSG: 'Wrong password',
}

// Sign up page texts
const SignUpPage = {
    SIGN_UP_WELCOME_TITLE: 'Welcome to Clip',
    SIGN_UP_SUPPORT_TEXT: 'We’re reinventing and simplifying the way connections are done between people. Connect anyone you know with a few click.',
    SIGN_UP_BUTTON: 'Create account',
    SIGN_UP_PICTURE_LABEL: 'Upload profile picture',
    SIGN_UP_NAME_LABEL: 'Name*',
    SIGN_UP_NAME_PLACEHOLDER: 'Enter your name',
    SIGN_UP_EMAIL_LABEL: 'Email*',
    SIGN_UP_EMAIL_PLACEHOLDER: 'Enter your email',
    SIGN_UP_PASSWORD_LABEL: 'Password*',
    SIGN_UP_PASSWORD_PLACEHOLDER: 'Create a password',
    SIGN_UP_HAVE_ACCOUNT: 'Already have an account?',
    SIGN_UP_GO_TO_SIGN_IN: 'Sign in',
    SIGN_UP_EMAIL_ERR_MSG: 'Wrong email format',
    SIGN_UP_PASSWORD_ERR_MSG: 'Password can\'t be empty',
    SIGN_UP_NAME_ERR_MSG: 'Can\'t be empty',
}

// Share clip page texts
const ShareClipPage = {
    SHARE_CLIP_BUSINESS_MESSAGE: "I think you can be valuable for each other and achieve new goals together :) \n\n",
    SHARE_CLIP_ROMANCE_MESSAGE: "It's almost Valentine's day and I think you two can be a great match :) \n\n",
    SHARE_CLIP_CLICK_ON_LINK: "Click on the link to respond to your match \nThe Clip link will be valid for 48 hours only.\n",
    SHARE_CLIP_CLIP_MESSAGE:'Clip message',
    SHARE_CLIP_START_CLIP:'Start a Clip!',
    SHARE_CLIP_SHARE_WITH:'Invite ',
}

// Share match modal texts
const ShareMatchModal = {
    SHARE_MATCH_MODAL_SEND_CLIP_VIA: 'Share clip via'
}

// Clip input component texts
const ClipInput = {
    CLIP_INPUT_DEFAULT_ERR_MSG: 'Error',
}

// Clip started modal texts
const ClipStartedModal = {
    CLIP_STARTED_MODAL_TITLE: 'Booooom! It’s a Clip!',
    CLIP_STARTED_MODAL_CLIPPED_TITLE: 'You\'ve just Clipped ',
    CLIP_STARTED_MODAL_BODY: 'We will keep you updated if the match is successful. in the meantime you can always see the response status on your personal area.',
    CLIP_STARTED_MODAL_BUTTON: 'Great! Thanks'
}

// Create a Clip page texts
const CreateAClipPage  = {
    CREATE_A_CLIP_TITLE_WITHOUT_NAME: 'Hello ',
    CREATE_A_CLIP_DESCRIPTION: 'Who you gonna Clip today?',
    CREATE_A_CLIP_CATEGORIES_BUSINESS: 'Business',
    CREATE_A_CLIP_CATEGORIES_ROMANCE: 'Romance',
    CREATE_A_CLIP_NAME_TITLE:'Clipped Name',
    CREATE_A_CLIP_CLIP_THEM_BUTTON:'Clip\'em 🚀',
    CREATE_A_CLIP_SCORE:'You will earn 5 points!',
    ERROR_MSG_NAME_FORM: 'Please enter a name'
}

const AppBar = {
    HOME:'Home',
    MY_CLIPS:'My Clips',
    CLIPS_I_CREATED: 'Clips By Me',
    MESSAGES:'Messages',
    APP_BAR_USER_LOG_OUT:'Log Out',
    APP_BAR_USER_VIEW_PROFFILE:'View Profile'
}

const MyChatsPage = {
    MY_CHATS_HEADER: 'Messages',
    MY_CHATS_CARD_NO_MESSAGE: 'Say hello!',
    MY_CHAT_ALERT_FAILED_LOGIN: 'An error occurred, please try to reload the page..\nIf the problem continues contact us.',
    MY_CHAT_ALERT_NO_CHATS: 'You don\'t have open chats, wait until you\'ll be clipped!',
}

const RespondToClip = {
    RESPOND_TO_CLIP_TITLE: 'You got a new Clip by ',
    RESPOND_TO_CLIP_BODY: 'Click on the link to respond to your match\nThe Clip link will be valid for 48 hours only !',
    RESPOND_TO_CLIP_TIME_OUT: 'We\'re sorry but the time has passed and this clip is not valid anymore.'
}

const MyClipsPage = {
    MY_CLIPS_TITLE: 'My Clips',
    MY_CLIPS_SUBTITLE: 'Manage the Clips invitations you got',
}

const ClipsByMePage = {
    CLIPS_BY_ME_TITLE: 'Clips By Me',
    CLIPS_BY_ME_SUBTITLE: 'Manage the Clips you initiated',
}
const UserProfileModal = {
    USER_PROFILE_CLOSE: 'CLOSE',
    USER_PROFILE_YOUR_SCORE: 'Your Score',
    USER_PROFILE_POINTS: 'Points'
}

export const Texts = {
    SignInPage: SignInPage,
    SignUpPage: SignUpPage,
    ShareClipPage: ShareClipPage,
    ShareMatchModal: ShareMatchModal,
    ClipInput: ClipInput,
    CreateAClipPage: CreateAClipPage,
    ClipStartedModal: ClipStartedModal,
    AppBar: AppBar,
    MyChatsPage: MyChatsPage,
    RespondToClip: RespondToClip,
    MyClipsPage: MyClipsPage,
    ClipsByMePage: ClipsByMePage,
    UserProfileModal: UserProfileModal
}


export const AppPaths = {
    SignIn: '/sign-in',
    SignInWithParam: '/sign-in/:clipID/:index',
    SignUp: '/sign-up',
    ShareClip: '/share-clip/:name1/:name2/:category/:clipID',
    Home: '/Home',
    MyClips: '/MyClips',
    ClipsICreated: '/ClipsICreated',
    Messages: '/Messages',
    ClipChat: '/Messages/:chatID',
}