import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useSelector} from "react-redux";
import {selectToken} from "../../features/user";
import {GetClipsByMe} from "../../ClipClient/UserToServer";
import ErrorModal from "../../Components/Modal/ErrorModal/ErrorModal";
import {formattedDate} from "../../Utils/Helpers";
import {Status} from  '../../Components/Status'

const MyClipsTable = () => {

    const [clipsByMe, setClipsByMe] = useState([]);
    const token = useSelector(selectToken);
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorBody, setErrorBody] = useState('')

    const HideErrorModal = () =>
    {
        setShowErrorModal(false);
        setErrorBody('');
    }

    const createData = (participant, participantWith, clipDate, status) => {
        return { participant, participantWith, clipDate, status };
    }

    useEffect(() => {
        GetClipsByMe(token).then(response => {
            if (response.status === 202 || response.status === 200) {
                response.json().then(res => {
                    if (res.responseCode === 'Success') {
                        setClipsByMe(res.data.map(clip => {
                            // TODO : Need to add message to ClipDto or to remove from the cell from the table
                            return createData(clip.clippedAName, clip.clippedBName, clip.timeCreated, clip.clipStatus);
                        }));
                    }
                    else {
                        setErrorBody(res.message);
                        setShowErrorModal(true);
                    }
                });
            }
            else {
                response.json().then(res => {
                    setErrorBody(res.message);
                    setShowErrorModal(true);
                });
            }
        })
    }, []);

    return (
        <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{backgroundColor:'#F9FAFB'}}>
                    <TableRow>
                        <TableCell>Participant</TableCell>
                        <TableCell align="left">With</TableCell>
                        <TableCell align="left">Clip Date </TableCell>
                        <TableCell align="left">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clipsByMe.map((row) => (
                        <TableRow
                            key={row.participant}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{row.participant}</TableCell>
                            <TableCell align="left">{row.participantWith}</TableCell>
                            <TableCell align="left">{formattedDate(row.clipDate)}</TableCell>
                            <TableCell align="left">
                                <Status status={row.status} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <ErrorModal body={errorBody} showModal={showErrorModal} onClose={HideErrorModal}/>
        </>
);
}

export default MyClipsTable;