import * as React from 'react';
import {
    AppBar,
    Toolbar,
    Button,
} from '@mui/material';
import {
    FavoriteBorderOutlined,
    HomeOutlined,
    LogoutOutlined,
    MailOutline,
} from "@mui/icons-material";
import AppBarStyle from './ClipAppBar.module.css';
import Logo from '../Assets/clipLogo-with_name.svg'
import {useLocation, Link} from 'react-router-dom'
import {AppPaths, Texts} from "../Utils/Texts";
import {selectName} from "../features/user";
import { useSelector} from "react-redux";
import {NotificationsPopover} from "../Components/AppBarNotifications";
import {UserPopover} from "../Components/AppBarUser";

export const ClipToolBarButtons = ({icon, path, text, appPath}) => {
    return (
        <Button
            startIcon={icon}
            className={AppBarStyle.ToolBarButton}
            component={Link}
            to={path}
            sx={{
                fontFamily: 'Inter, sans-serif',
                fontStyle: 'normal',
                fontSize: 16,
                color: appPath.pathname === path ? '#6941C6' : '#344054',
                backgroundColor: appPath.pathname === path ? '#F9F5FF' : 'none',
                textTransform: 'none',
                mr: 3
            }}
        >
            {text}
        </Button>
    )
}

const PAGES_WITHOUT_APP_BAR = [AppPaths.SignIn, AppPaths.SignUp]

const ClipAppBar = () => {
    const appPath = useLocation();
    const username = useSelector(selectName);

    if (PAGES_WITHOUT_APP_BAR.includes(appPath.pathname) || appPath.pathname.startsWith(AppPaths.SignIn)) {
        return null
    }
    return (
        <AppBar position="sticky" style={{background: 'white'}}>
            <Toolbar className={AppBarStyle.ToolBarContainer}>
                <Button component={Link} to={AppPaths.Home}>
                    <img src={Logo} alt={"ClipLogo"} style={{marginTop: 6}}/>
                </Button>
                <div>
                    <ClipToolBarButtons icon={<HomeOutlined/>} path={AppPaths.Home} text={Texts.AppBar.HOME}
                                        appPath={appPath}/>
                    <ClipToolBarButtons icon={<FavoriteBorderOutlined/>} path={AppPaths.MyClips}
                                        text={Texts.AppBar.MY_CLIPS}
                                        appPath={appPath}/>
                    <ClipToolBarButtons icon={<LogoutOutlined/>} path={AppPaths.ClipsICreated}
                                        text={Texts.AppBar.CLIPS_I_CREATED}
                                        appPath={appPath}/>
                    <ClipToolBarButtons icon={<MailOutline/>} path={AppPaths.Messages} text={Texts.AppBar.MESSAGES}
                                        appPath={appPath}/>
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:"center"}}>
                    <NotificationsPopover />
                    <UserPopover username={username}/>
                </div>
            </Toolbar>
        </AppBar>);
};
export default ClipAppBar;