import React from "react";
import '../../App.css';

const clipIsTyping = () => {

    return (
        <div>
            <div className="snippet" data-title=".dot-falling">
                <div className="stage">
                    <div className="dot-falling"/>
                </div>
            </div>
        </div>
    );
}

export default clipIsTyping;