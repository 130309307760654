import React, {useEffect, useState} from "react";
import MyClipsStyles from './MyClipsPage.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {GetMyClips} from "../../ClipClient/UserToServer";
import {useSelector} from "react-redux";
import { selectToken, selectUserId} from "../../features/user";
import {generatePath, useNavigate} from "react-router-dom";
import {AppPaths} from "../../Utils/Texts";
import ErrorModal from "../../Components/Modal/ErrorModal/ErrorModal";
import {Status} from '../../Components/Status'
import {formattedDate} from "../../Utils/Helpers";
import Countdown from "react-countdown";

Date.prototype.addHours= function(h){
    this.setHours(this.getHours()+h);
    return this;
}

const MyClipsTable = () => {

    const [myClips, setMyClips] = useState([]);
    const token = useSelector(selectToken);
    let userID = useSelector(selectUserId);
    const navigate = useNavigate();
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorBody, setErrorBody] = useState('')

    const HideErrorModal = () =>
    {
        setShowErrorModal(false);
        setErrorBody('');
    }

    const createData = (otherSideName, creationDate, status, chat) => {
        return { otherSideName, creationDate, status, chat };
    }

    const getOtherSideName = (clippedAName, clippedBName, clippedAID) => {
        return userID === clippedAID ? clippedBName : clippedAName
    }

    const nextPath = (path) => {
        navigate(path);
    }

    const getCounter = (creationDate) => {
        return (
            <div className={MyClipsStyles.counter}>
                    <Countdown date={new Date(creationDate).addHours(48)} />
                    <p style={{paddingLeft: '8px'}}>time left</p>
            </div>
        );
    }

    const getChat = (chat) => {
        if (chat === null)
            return null;
        else {
            let path = generatePath(AppPaths.ClipChat, {
                chatID: chat
            });
            return (<button className={MyClipsStyles.GoToChatLink} onClick={() => nextPath(path)}>
                Go to Chat
            </button>);
        }
    }

    useEffect(() => {
        GetMyClips(token).then(response => {
            if (response.status === 202 || response.status === 200) {
                response.json().then(res => {
                    if (res.responseCode === 'Success') {
                        setMyClips(res.data.map(clip => {
                            let otherSideName = getOtherSideName(clip.clippedAName, clip.clippedBName, clip.clippedAUserId);
                            return createData(otherSideName, clip.timeCreated, clip.clipStatus, clip.chatId);
                        }));
                    }
                    else {
                        setErrorBody(res.message);
                        setShowErrorModal(true);
                    }
                });
            }
            else {
                response.json().then(res => {
                    setErrorBody(res.message);
                    setShowErrorModal(true);
                });
            }
        })
    }, []);

    return (
        <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{backgroundColor:'#F9FAFB'}}>
                    <TableRow>
                        <TableCell>You clipped with</TableCell>
                        <TableCell align="left">Clip date</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {myClips.map((row) => (
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.otherSideName}
                            </TableCell>
                            <TableCell align="left">{formattedDate(row.creationDate)}</TableCell>
                            <TableCell align="left">
                                <Status status={row.status}/>
                            </TableCell>
                            <TableCell align="left">{row.chat ? getChat(row.chat) : getCounter(row.creationDate)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <ErrorModal body={errorBody} showModal={showErrorModal} onClose={HideErrorModal}/>
        </>
    );
}

export default MyClipsTable;
