import React, {useState} from 'react';
import CreateAClipStyles from './CreateAClip.module.css';
import ToggleCategory from './ToggleCategory';
import NamesForm from './NamesForm';
import {AppPaths, Texts} from '../../Utils/Texts';
import {ClipCategory} from '../../Utils/Types';
import {generatePath, useNavigate} from 'react-router-dom';
import {StartClip} from "../../ClipClient/UserToServer";
import {validateName} from "../../Utils/Helpers";
import {useSelector} from 'react-redux';
import {selectToken} from "../../features/user";
import ErrorModal from "../../Components/Modal/ErrorModal/ErrorModal";

const CreateAClipBody = ({category, handleChangeCategory}) => {
    const [name1, setName1] = useState(undefined);
    const [name2, setName2] = useState(undefined);
    const [name1Error, setName1Error] = useState(false);
    const [name2Error, setName2Error] = useState(false);
    const navigate = useNavigate();
    const token = useSelector(selectToken);
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorBody, setErrorBody] = useState('')

    const HideErrorModal = () =>
    {
        setShowErrorModal(false);
        setErrorBody('');
    }

    const handleChangeName1 = (name) => {
        setName1(name);
        name1Error && setName1Error(false)
    }

    const handleChangeName2 = (name) => {
        setName2(name);
        name2Error && setName2Error(false)
    }

    const onClipThem = () => {
        if (!validateName(name1)) {
            setName1Error(true)
        }
        if (!validateName(name2)) {
            setName2Error(true)
        }

        if (!name1Error && !name2Error) {
            StartClip(token, name1, name2, category).then(response => {
                if (response.status === 202 || response.status === 200) {
                    response.json().then(res => {
                        if (res.responseCode === 'Success') {
                            const path = generatePath(AppPaths.ShareClip, {
                                name1: name1,
                                name2: name2,
                                category: category,
                                clipID: res.data
                            });
                            navigate(path)
                        }
                        else {
                            setErrorBody(res.message);
                            setShowErrorModal(true);
                        }
                    });
                }
                else {
                    response.json().then(res => {
                        setErrorBody(res.message);
                        setShowErrorModal(true);
                    });
                }
            })
        }
    }


    return (
        <div className={CreateAClipStyles.Body}>
            <div style={{flex: 1}}>
                <ToggleCategory category={category} handleChangeCategory={handleChangeCategory}/>
            </div>
            <div style={{flex: 2}}>
                <NamesForm
                    category={category}
                    name1={name1}
                    name2={name2}
                    handleChangeName1={handleChangeName1}
                    handleChangeName2={handleChangeName2}
                    name1Error={name1Error}
                    name2Error={name2Error}
                />
            </div>

            <div className={CreateAClipStyles.BodyBottom}>
                <div className={CreateAClipStyles.Line}/>
                <div style={{margin: '1%'}}/>
                <button
                    className={category === ClipCategory.BUSINESS ? CreateAClipStyles.ClipThemButtonBusiness : CreateAClipStyles.ClipThemButtonRomance}
                    onClick={onClipThem}
                >
                    <div className={CreateAClipStyles.ClipThemButtonText}>
                        {Texts.CreateAClipPage.CREATE_A_CLIP_CLIP_THEM_BUTTON}
                    </div>
                </button>
                <div
                    className={category === ClipCategory.BUSINESS ? CreateAClipStyles.ScoreTextBusiness : CreateAClipStyles.ScoreTextRomance}>
                    {Texts.CreateAClipPage.CREATE_A_CLIP_SCORE}
                </div>
            </div>
            <ErrorModal body={errorBody} showModal={showErrorModal} onClose={HideErrorModal}/>
        </div>
    );
};

export default CreateAClipBody;