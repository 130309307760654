
const url = '212.80.207.120'
// const url = 'localhost'

export function SignInAuthenticate(email, password) {
        return fetch(`http://${url}:31288/gw/api/auth/auth-controller/sign-in`, {
            "headers": {
                "accept": "*/*",
                "content-type": "application/json-patch+json",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-origin"
            },
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": `{\n  \"email\": \"${email}\",\n  \"password\": \"${password}\"\n}`,
            "method": "POST",
            "mode": "cors",
            "credentials": "include"
        });
    }

export function SignUpAuthenticate(name, email, password) {
    return fetch(`http://${url}:31288/gw/api/auth/auth-controller/sign-up`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{\n  \"name\": \"${name}\",\n  \"email\": \"${email}\",\n  \"password\": \"${password}\"\n}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}

export function ResetPassword(email) {
    return fetch(`http://${url}:31288/gw/api/auth/auth-controller/reset-password`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{\n  \"Email\": \"${email}\"\n}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}

export function SignUp(token, name, email, picture) {
    return fetch(`http://${url}:31288/gw/api/clip/user-controller/sign-up`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{\n  \"name\": \"${name}\",\n  \"email\": \"${email}\",\n \"picture\": \"${picture}\"\n}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}

export function SignIn(token) {
    return fetch(`http://${url}:31288/gw/api/clip/user-controller/sign-in`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}

export function StartClip(token, sideAName, sideBName, category) {
    return fetch(`http://${url}:31288/gw/api/clip/clip-controller/start-clip`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{\n  \"sideAName\": \"${sideAName}\",\n  \"sideBName\": \"${sideBName}\",\n  \"category\": \"${category}\"\n}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}

export function AcceptClip(token, clipId, userId, index) {
    const clippedIndex = index === '1' ? 'First' : 'Second';
    return fetch(`http://${url}:31288/gw/api/clip/clip-controller/accept-clip`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{\n  \"clipId\": \"${clipId}\",\n  \"userId\": \"${userId}\",\n  \"index\": \"${clippedIndex}\"\n}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}

export function RejectClip(token, clipId, userId, index) {
    const clippedIndex = index === '1' ? 'First' : 'Second';
    return fetch(`http://${url}:31288/gw/api/clip/clip-controller/reject-clip`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{\n  \"clipId\": \"${clipId}\",\n  \"userId\": \"${userId}\",\n  \"index\": \"${clippedIndex}\"\n}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}

export function GetClip(token, clipId) {
    return fetch(`http://${url}:31288/gw/api/clip/clip-controller/get-clip`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{\n  \"clipId\": \"${clipId}\"\n}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}

export function GetUserDetailsFromServer(token, userID) {
    return fetch(`http://${url}:31288/gw/api/clip/user-controller/get-user`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{\n  \"userId\": \"${userID}\"\n}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}


export function GetNotifications(token) {
    return fetch(`http://${url}:31288/gw/api/clip/user-controller/get-notifications`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
}

export function GetMyClips(token) {
    return fetch(`http://${url}:31288/gw/api/clip/clip-controller/get-my-clips`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
}

export function GetClipsByMe(token) {
    return fetch(`http://${url}:31288/gw/api/clip/clip-controller/get-my-clips-by-me`, {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json-patch+json",
            "Authorization": `Bearer ${token}`,
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-origin"
        },
        "referrerPolicy": "strict-origin-when-cross-origin",
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
}