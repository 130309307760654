import React from "react";
import SignInPage from "./SingIn/SignInPage";
import SignUpPage from "./SignUp/SignUpPage";
import ShareClipPage from "./ShareClip/ShareClipPage";
import {Routes, Route, Navigate} from "react-router-dom";
import CreateAClipPage from "./CreateAClip/CreateAClipPage";
import {AppPaths} from "../Utils/Texts";
import MyChatsPage from "./MyChats/MyChatsPage";
import MyClipsPage from "./MyClips/MyClipsPage";
import ClipsByMePage from "./ClipsByMe/ClipsByMePage";

const Routing = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate replace to={AppPaths.SignIn} />} />
            <Route path={AppPaths.SignIn} element={<SignInPage/>}/>
            <Route path={AppPaths.SignInWithParam} element={<SignInPage/>}/>
            <Route path={AppPaths.SignUp}  element={<SignUpPage/>}/>
            <Route path={AppPaths.ShareClip}  element={<ShareClipPage/>}/>
            <Route path={AppPaths.Home} element={<CreateAClipPage />} />
            <Route path={AppPaths.Messages} element={<MyChatsPage />} />
            <Route path={AppPaths.ClipChat} element={<MyChatsPage />} />
            <Route path={AppPaths.MyClips} element={<MyClipsPage />} />
            <Route path={AppPaths.ClipsICreated} element={<ClipsByMePage />} />
        </Routes>
    );
};

export default Routing;