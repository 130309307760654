import React from "react";
import ClipsByMeStyles from './ClipsByMePage.module.css';
import ClipsByMeHeader from "./ClipsByMeHeader";
import ClipsByMeTable from "./ClipsByMeTable";

const ClipsByMePage = () => {

    return (
        <div className={ClipsByMeStyles.MainContainer}>
            <ClipsByMeHeader />
            <ClipsByMeTable />
        </div>
    );
}

export default ClipsByMePage;