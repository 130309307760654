import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {
        clipID: '',
        index: 0,
        clipperName:'',
        otherClippedName: ''

    }
};

export const newClipSlice = createSlice({
    name:'newClip',
    initialState,
    reducers: {
        addClip: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const {addClip} = newClipSlice.actions;

export const selectClipID = (state) => state.newClip.value.clipID;
export const selectIndex = (state) => state.newClip.value.index;
export const selectClipperName = (state) => state.newClip.value.clipperName;
export const selectOtherClippedName = (state) => state.newClip.value.otherClippedName;

export default newClipSlice.reducer;