import React, {useRef, useState} from "react";
import signUpStyles from './SignUpPage.module.css';
import {AppPaths, Texts} from "../../Utils/Texts";
import UploadPicture from '../../Assets/uploadImg-icon.svg'
import {SignUp, SignUpAuthenticate} from "../../ClipClient/UserToServer";
import {getPictureUrl, validateEmail, validateName, validatePassword} from "../../Utils/Helpers";
import ClipInput from "../../Components/ClipInput/ClipInput";
import {generatePath, useNavigate} from "react-router-dom";
import {createUser} from "../../features/user";
import {useDispatch} from "react-redux";
import ErrorModal from "../../Components/Modal/ErrorModal/ErrorModal";

const SignUpForm = () => {

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorBody, setErrorBody] = useState('')

    const HideErrorModal = () =>
    {
        setShowErrorModal(false);
        setErrorBody('');
    }
    const fileRef = useRef();
    const [pictureUri, setPictureUri] = useState('')

    const fileToDataUri = (file) => new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result)
        };
        reader.readAsDataURL(file);
    })

    const handleFileInputChange = async (file) => {
        if (!file) {
            setPictureUri('');
            return;
        }

        await fileToDataUri(file)
            .then(dataUri => {
                setPictureUri(dataUri.split(',')[1])
            })
    };

    const handleSetName = (value) => {
        if (validateName(value))
            setNameError(false);
        else
            setNameError(true);
        setName(value);
    }

    const handleSetEmail = (value) => {
        if (validateEmail(value))
            setEmailError(false);
        else
            setEmailError(true);
        setEmail(value);
    }

    const handleSetPassword = (value) => {
        if (validatePassword(value))
            setPasswordError(false);
        else
            setPasswordError(true);
        setPassword(value);
    }

    const handleSignUp = () => {
        if (!emailError && !passwordError) {
            SignUpAuthenticate(name, email, password).then(response => {
                if (response.status === 202) {
                    response.json().then(res => {
                        if (res.responseCode === 'Success') {
                            let token = res.data.authToken.token;
                            let expirationDate = res.data.authToken.expirationDate;
                            console.log(token, expirationDate);
                            SignUp(token, name, email, pictureUri).then(response => {
                                if (response.status === 202 || response.status === 200) {
                                    response.json().then(res => {
                                        if (res.responseCode === 'Success') {
                                            let userId = res.data.userId;
                                            let picture = getPictureUrl(pictureUri);
                                            dispatch(createUser({name: name, token: token, userId: userId, picture:picture}));
                                            const path = generatePath(AppPaths.Home);
                                            navigate(path);
                                        }
                                        else {
                                            setErrorBody(res.message);
                                            setShowErrorModal(true);
                                        }
                                    })
                                }
                                else {
                                    setErrorBody(response.message);
                                    setShowErrorModal(true);
                                }
                            });
                        }
                        else {
                            setErrorBody(res.message);
                            setShowErrorModal(true);
                        }
                    });
                }
                else {
                    response.json().then(res => {
                        setErrorBody(res.message);
                        setShowErrorModal(true);
                    });
                }
            })
        }
    }

    const getDetailsElement = () => {
        return (
            <div className={signUpStyles.Details}>
                <div>
                    <div style={{display: 'flex'}}>
                        {Texts.SignUpPage.SIGN_UP_PICTURE_LABEL}
                    </div>
                    <div style={{margin: '2%'}}/>
                    <div>
                        <button className={signUpStyles.UploadImgButton} onClick={() => fileRef.current.click()}>
                            <img src={UploadPicture} alt={"Upload Image"}/>
                        </button>
                        <input
                            ref={fileRef}
                            onChange={(event)=>handleFileInputChange(event.target.files[0] || null)}
                            multiple={false}
                            type="file"
                            hidden
                            accept="image/png, image/jpeg"
                        />
                    </div>
                </div>
                <ClipInput
                    style={{width: '100%'}}
                    title={Texts.SignUpPage.SIGN_UP_NAME_LABEL}
                    placeHolder={Texts.SignUpPage.SIGN_UP_NAME_PLACEHOLDER}
                    value={name}
                    setValue={handleSetName}
                    error={nameError}
                    errorMsg={Texts.SignUpPage.SIGN_UP_NAME_ERR_MSG}
                />
                <ClipInput
                    style={{width: '100%'}}
                    title={Texts.SignUpPage.SIGN_UP_EMAIL_LABEL}
                    placeHolder={Texts.SignUpPage.SIGN_UP_EMAIL_PLACEHOLDER}
                    value={email}
                    setValue={handleSetEmail}
                    error={emailError}
                    errorMsg={Texts.SignUpPage.SIGN_UP_EMAIL_ERR_MSG}
                />
                <ClipInput
                    style={{width: '100%'}}
                    title={Texts.SignUpPage.SIGN_UP_PASSWORD_LABEL}
                    placeHolder={Texts.SignUpPage.SIGN_UP_PASSWORD_PLACEHOLDER}
                    value={password}
                    setValue={handleSetPassword}
                    error={passwordError}
                    errorMsg={Texts.SignUpPage.SIGN_UP_PASSWORD_ERR_MSG}
                    type={"password"}
                />
            </div>
        );
    }

    const getActionsElement = () => {
        return (
            <div className={signUpStyles.Actions}>
                <button onClick={handleSignUp} className={signUpStyles.SignUpButton}>
                    {Texts.SignUpPage.SIGN_UP_BUTTON}
                </button>
            </div>
        );
    }

    return (
        <div className={signUpStyles.Form}>
            {getDetailsElement()}
            {getActionsElement()}
            <ErrorModal body={errorBody} showModal={showErrorModal} onClose={HideErrorModal}/>
        </div>
    );
};

export default SignUpForm;