import React, {useState} from "react";
import CreateAMatchStyles from './ShareClipPage.module.css';
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {AppPaths, Texts} from "../../Utils/Texts";
import ShareClipMessageForm from "./ShareClipMessageForm";
import ShareClipActions from "./ShareClipActions";
import ClipStartedModal from "../../Components/Modal/ClipStartedModal/ClipStartedModal";

export const ShareClipPage = () => {
    const { name1, name2, category, clipID } = useParams()
    const [message, setMessage] = useState(getMessage(category, name1, name2))
    const [firstShared, setFirstShared] = useState(false)
    const [secondShared, setSecondShared] = useState(false)
    const [displayClipIt, setDisplayClipIt] = useState(false)
    const navigate = useNavigate();

    const onDisplayClipItClose = () => {
        setDisplayClipIt(false);
        const path = generatePath(AppPaths.Home);
        navigate(path);
    }

    const handleClipIt = () => {
        setDisplayClipIt(true);
    }

    return(
        <div className={CreateAMatchStyles.CreateAMatch}>
            <h2>{Texts.ShareClipPage.SHARE_CLIP_CLIP_MESSAGE}</h2>
            <div style={{flex: 1}}>
                <ShareClipMessageForm message={message} setMessage={setMessage}/>
            </div>
            <div style={{flex: 2}}>
                <ShareClipActions
                    firstShared={firstShared}
                    setFirstShared={setFirstShared}
                    secondShared={secondShared}
                    setSecondShared={setSecondShared}
                    name1={name1} name2={name2}
                    clipID={clipID} message={message}
                />
            </div>
            <div className={CreateAMatchStyles.StartClip}>
                <div className={CreateAMatchStyles.Button} >
                    <button onClick={handleClipIt} className={CreateAMatchStyles.Button} disabled={!(firstShared && secondShared)}>
                        {Texts.ShareClipPage.SHARE_CLIP_START_CLIP}
                    </button>
                </div>
            </div>
            {displayClipIt && <ClipStartedModal onClose={onDisplayClipItClose} nameA={name1} nameB={name2}/>}
        </div>
    );
}

const getMessage = (category,name1,name2) => {
    const msg = category==='BUSINESS'?
        Texts.ShareClipPage.SHARE_CLIP_BUSINESS_MESSAGE :
        Texts.ShareClipPage.SHARE_CLIP_ROMANCE_MESSAGE
    return `Hi ${name1} and ${name2}! \n` + msg + Texts.ShareClipPage.SHARE_CLIP_CLICK_ON_LINK
}

export default ShareClipPage;