import React, {useState} from "react";

import clipShareButtonStyles from './ClipShareButton.module.css';

const ClipShareButton = (props) => {

    const {title, handleClick, disable} = props;
    const [focus, setFocus] = useState(false);

    const handleOnFocus = () => {
        if(!disable) {
            setFocus(true);
        }
    };

    const handleOnBlur = () => {
        if(!disable) {
            setFocus(false);
        }
    };

    return (
        <div onFocus={handleOnFocus}
             onBlur={handleOnBlur}>
            <button
                className={`${clipShareButtonStyles.ButtonWithLabel} 
                            ${focus ? clipShareButtonStyles.ButtonFocus : ''}
                            ${disable ? clipShareButtonStyles.ButtonDisable : ''}
                           `}
                onClick={handleClick}
                disabled={disable}
            >
                <div className={clipShareButtonStyles.Label}>
                    {disable ?
                        <font color={'#C9C9CA'}>{title}</font> :
                        <font color={'black'}>{title}</font>}
                </div>
            </button>
        </div>
    );
};

export default ClipShareButton;