import React, {useState} from "react";

import clipInputStyles from './ClipInput.module.css';
import {Texts} from "../../Utils/Texts";

const ClipInput = ({title, placeHolder, value, setValue, disable, error, errorMsg, type}) => {

    const [focus, setFocus] = useState(false);

    const handleInputChange = (event) => {
        if (event.target.value && event.target.value === ''){
            setValue(undefined);
        }
        else {
            setValue(event.target.value);
        }
    };

    const handleOnFocus = () => {
        if (!error && !disable){
            setFocus(true);
        }
    };

    const handleOnBlur = () => {
        if(!error && !disable){
            setFocus(false);
        }
    };

    return (
        <div className={clipInputStyles.InputWithLabel}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}>
                <div className={clipInputStyles.label}>
                    {title}
                </div>
                <input
                    type={type ? type : "text"}
                    name="name"
                    className={`${clipInputStyles.InputContainer}  
                                ${disable ? clipInputStyles.InputDisable : 
                                    error ? clipInputStyles.InputError : 
                                        focus ? clipInputStyles.InputFocus : ''}
                    `}
                    placeholder={placeHolder}
                    value={value}
                    onChange={handleInputChange}
                    disabled={disable}
                />
            {error && !disable &&
                <div className={clipInputStyles.ErrorMsg}>
                    {errorMsg ? errorMsg : Texts.ClipInput.CLIP_INPUT_DEFAULT_ERR_MSG}
                </div>
            }
        </div>
    );
};

export default ClipInput
