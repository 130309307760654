import React, {useEffect, useState} from "react";
import {Modal} from "@mui/material";
import UserProfileStyle from './UserProfileModal.module.css';
import {Texts} from "../../../Utils/Texts";
import {SingleAvatar} from "../Avatars/Avatars";
import {GetUserDetailsFromServer} from "../../../ClipClient/UserToServer";
import {useSelector} from "react-redux";
import ErrorModal from "../ErrorModal/ErrorModal";
import {selectName, selectPicture, selectToken, selectUserId} from "../../../features/user";

const UserProfileModal = ({showModal,onClose}) => {
    const token = useSelector(selectToken)
    const userId = useSelector(selectUserId)
    const username = useSelector(selectName)
    const userPicture = useSelector(selectPicture)
    const [score, setScore] = useState(undefined)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorBody, setErrorBody] = useState('')

    const HideErrorModal = () =>
    {
        setShowErrorModal(false);
        setErrorBody('');
    }

    useEffect(() => {
        if(showModal) {
            GetUserDetailsFromServer(token, userId).then(response => {
                if (response.status === 202 || response.status === 200) {
                    response.json().then(res => {
                        if (res.responseCode === 'Success') {
                            setScore(res.data.score)
                        } else {
                            setErrorBody(res.message);
                            setShowErrorModal(true);
                        }
                    })
                } else {
                    setErrorBody(response.message);
                    setShowErrorModal(true);
                }
            });
        }
    });

    return (
        <>
            {score !== undefined ?
                <Modal open={showModal}>
                    <div className={UserProfileStyle.Box}>
                        <SingleAvatar img={userPicture} name={username}/>
                        <div className={UserProfileStyle.TextTitle}>
                            {username}
                        </div>
                        <div className={UserProfileStyle.TextBody}>
                            {Texts.UserProfileModal.USER_PROFILE_YOUR_SCORE + ' - ' + score + ' '+ Texts.UserProfileModal.USER_PROFILE_POINTS}
                        </div>
                        <div className={UserProfileStyle.ButtonsContainer}>
                        <button className={UserProfileStyle.Button} onClick={onClose}>
                            <div className={UserProfileStyle.ButtonText}>
                                {Texts.UserProfileModal.USER_PROFILE_CLOSE}
                            </div>
                        </button>
                        </div>
                    </div>
                </Modal> :
                null
            }
            <ErrorModal body={errorBody} showModal={showErrorModal} onClose={HideErrorModal}/>
        </>
    );
};

export default UserProfileModal;