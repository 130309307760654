import React from "react";
import signUpStyles from './SignUpPage.module.css';
import DetailsSection from "./DetailsSection";
import WelcomeSection from "./WelcomeSection";

const SignUpPage = () => {
    return (
        <div className={signUpStyles.SignUp}>
            <DetailsSection/>
            <WelcomeSection/>
        </div>
    );
};

export default SignUpPage;