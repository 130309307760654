import React, {useEffect, useState} from "react";
import MyChatsStyles from './MyChatsPage.module.css';
import OnlineBadge from '../../Assets/OnlineBadge.svg';
import OfflineBadge from '../../Assets/OfflineBadge.svg';
import {GetUserDetailsFromServer} from "../../ClipClient/UserToServer";
import {useSelector} from "react-redux";
import {selectToken} from "../../features/user";
import {SingleAvatar} from "../../Components/Modal/Avatars/Avatars";
import {getPictureUrl} from "../../Utils/Helpers";

const ClipChatHeader = (props) => {
    console.log(props);
    const token = useSelector(selectToken);
    const [sideB, setSideB] = useState(undefined);
    const [isOnline, setIsOnline] = useState(false);
    const [dataReady, setDataReady] = useState(false);


    useEffect(() => {
        if (props.chat !== null && props.chat !== undefined) {
            let chatMembers = props.chat.people.map(member => member.person);
            let sideB = chatMembers.filter(member => member.username.substr(0, 36) !== props.user)[0];
            setIsOnline(sideB.is_online);
            let user = sideB.username.substr(0, 36);
            GetUserDetailsFromServer(token, user).then((getUserResult) => {
                if (getUserResult.status === 202 || getUserResult.status === 200) {
                    getUserResult.json().then(res => {
                        if (res.responseCode === 'Success') {
                            setSideB(res.data);
                            setDataReady(true);
                        }
                    })
                }
            });
        }
    }, [props.chat]);

    return (
            dataReady ?
                <div className={MyChatsStyles.chatTitle}>
                    <div className={MyChatsStyles.content}>
                        <div className={MyChatsStyles.avatarChat}>
                            <SingleAvatar img={getPictureUrl(sideB.picture)} name={sideB.name}/>
                        </div>
                        <div className={MyChatsStyles.textContainer}>
                            <div className={MyChatsStyles.text}>
                                {sideB ? sideB.name : ""}
                            </div>
                            <div  className={MyChatsStyles.text}>
                                <img src={isOnline ? OnlineBadge : OfflineBadge} alt={"Status"} />
                            </div>
                        </div>
                    </div>
                </div> :
                null
    )
}

export default ClipChatHeader;