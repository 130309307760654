import CreateAMatchStyles from "./ShareClipPage.module.css";
import React, {useState} from "react";
import {Texts} from "../../Utils/Texts";
import ShareClipSocialsModal from "../../Components/Modal/ShareClipSocialsModal/ShareClipSocialsModal";
import ClipShareButton from "../../Components/ClipShareButton/ClipShareButton";

const ShareClipActions = (props) => {

    const {firstShared = false, setFirstShared, secondShared, setSecondShared, name1, name2, clipID, message} = props
    const [displayShare1, setDisplayShare1] = useState(false)
    const [displayShare2, setDisplayShare2] = useState(false)

    return (
        <div className={CreateAMatchStyles.ShareContainer}>
            <div className={CreateAMatchStyles.ShareButton}>
                    <ClipShareButton title={Texts.ShareClipPage.SHARE_CLIP_SHARE_WITH + name1}
                                     handleClick={()=>setDisplayShare1(true)}
                                     disable={false}/>
                    <ClipShareButton title={Texts.ShareClipPage.SHARE_CLIP_SHARE_WITH + name2}
                                     handleClick={()=>setDisplayShare2(true)}
                                     disable={!firstShared}/>
            </div>
            {displayShare1 && <ShareClipSocialsModal message={message} onClose={() => setDisplayShare1(false)}
                                                     onShare={() => setFirstShared(true)} url={`/sign-in/${clipID}/1`}
                                                     side={1}/>}
            {displayShare2 && <ShareClipSocialsModal message={message} onClose={() => setDisplayShare2(false)}
                                                     onShare={() => setSecondShared(true)} url={`/sign-in/${clipID}/2`}
                                                     side={2}/>}
        </div>
    );
}

export default ShareClipActions;