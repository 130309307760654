import React, {useState} from "react";
import '../../App.css';
import MyChatsStyles from './MyChatsPage.module.css';
import {ChatEngine, ChatFeed} from "react-chat-engine";
import {useSelector} from "react-redux";
import {selectName, selectUserId} from "../../features/user";
import ClipChatHeader from "./ClipChatHeader";
import ClipMessagesHeader from "./ClipMessagesHeader";
import ClipIsTyping from './ClipIsTyping';
import {Texts} from "../../Utils/Texts";

const MyChatsPage = () => {
    const user = useSelector(selectUserId);
    const name = useSelector(selectName)
    const [failedAuth, setFailedAuth] = useState(false);

    const clipLoginFailedMessage = () => {
        return (
            `<div class="${MyChatsStyles.AlertContainer}">
                <div class="${MyChatsStyles.AlertText}">
                    ${Texts.MyChatsPage.MY_CHAT_ALERT_FAILED_LOGIN}
                </div>
            </div>`
        );
    }

    const noChatsMessage = () => {
        return (
            `<div class="${MyChatsStyles.AlertContainer}">
                <div class="${MyChatsStyles.AlertText}">
                    ${Texts.MyChatsPage.MY_CHAT_ALERT_NO_CHATS}
                </div>
            </div>`
        );
    }

    return (
        <>
            <ChatEngine
                projectID='bb6f1509-b5b4-4a8d-a482-1d10ae252fbc'
                userName={user + name}
                userSecret={user}
                height='80vh'
                renderChatHeader={(chat) => { return <ClipChatHeader chat={chat} user={user}/> }}
                renderNewChatForm={(creds) => { return <ClipMessagesHeader /> }}
                renderChatSettings={(chatAppState) => {}}
                renderChatSettingsTop={(creds, chat) => {}}
                renderPeopleSettings={(creds, chat) => {}}
                renderPhotosSettings={(chat) => {}}
                renderOptionsSettings={(creds, chat) => {}}
                renderIsTyping={() => { return <ClipIsTyping /> }}
                onFailAuth={(props) => {setFailedAuth(true)}}
                renderChatFeed={(chatAppState) => {
                    console.log(chatAppState);
                    if (failedAuth) {
                        let failedToLogin = document.getElementById("ce-login-fail-breaker");
                        failedToLogin.innerHTML = clipLoginFailedMessage();
                    }
                    let noChats = document.getElementById("ce-ice-breaker");
                    if (noChats !== null && chatAppState.chats === {})
                        noChats.innerHTML = noChatsMessage();
                    else if (noChats !== null)
                        noChats.innerHTML = '';
                    let otherSideNames = document.getElementsByClassName("ce-their-message-sender");
                    for (let i = 0; i < otherSideNames.length; i++) {
                        otherSideNames[i].innerText = otherSideNames[i].innerText.substr(36);
                    }

                    return <ChatFeed {...chatAppState}/>;
                }}
            />
        </>
    );
}
export default MyChatsPage;

