import React, {useState} from 'react';
import CreateAClipStyle from './CreateAClip.module.css'
import CreateAClipHeader from './CreateAClipHeader';
import CreateAClipBody from './CreateAClipBody'
import {ClipCategory} from '../../Utils/Types';
import {useDispatch, useSelector} from "react-redux";
import {addClip, selectClipID} from "../../features/newClip";
import RespondToClipModal from "../../Components/Modal/RespondToClipModal/RespondToClipModal";

const CreatAClipPage = () => {
    const [category, setCategory] = useState(ClipCategory.BUSINESS)
    const clipID = useSelector(selectClipID)
    const dispatch = useDispatch()

    const onClipRespondClose = () => {
        dispatch(addClip({clipID: '', index: 0, clipperName: '', otherClippedName: ''}))
    }

    const handleChangeCategory = (event, newCategory) => {
        if (newCategory === ClipCategory.BUSINESS || newCategory === ClipCategory.ROMANCE) {
            setCategory(newCategory)
        }
    }

    return (
        <div
            className={category === ClipCategory.BUSINESS ? CreateAClipStyle.CreateAClipBusiness : CreateAClipStyle.CreateAClipRomance}>
            <CreateAClipHeader/>
            <CreateAClipBody category={category} handleChangeCategory={handleChangeCategory}/>
            {clipID && <RespondToClipModal clipID={clipID} onClose={onClipRespondClose}/>}
        </div>
    );
}

export default CreatAClipPage;