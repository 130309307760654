import {Modal, Stack} from "@mui/material";
import React from "react";
import ShareMatchModalStyles from './ShareClipSocialsModal.module.css';
import {FacebookMessengerShareButton, LinkedinShareButton, WhatsappShareButton, TelegramShareButton} from "react-share";
import {FacebookMessengerIcon, LinkedinIcon, TelegramIcon, WhatsappIcon,} from "react-share";
import { Texts } from "../../../Utils/Texts";

const  ShareClipSocialsModal = (props) => {
    const { message, onClose, onShare, url, side } = props;
    const applicationUrl = 'http://heduki.com';
    const shareAndClose = () => {
        onShare();
        onClose();
    }

    const getShareButtons = () => {
        // TODO:: change URL to the real one
        return (
            <Stack spacing={2} direction="row" style={{margin: 20}}>
                <LinkedinShareButton children={<LinkedinIcon round size={50}/>} onShareWindowClose={shareAndClose}
                                     url={`${applicationUrl}` + url} title={message}/>
                <WhatsappShareButton children={<WhatsappIcon round size={50}/>} onShareWindowClose={shareAndClose}
                                     url={`${applicationUrl}` + url} title={message}/>
                <TelegramShareButton children={<TelegramIcon round size={50}/>} onShareWindowClose={shareAndClose}
                                     url={`${applicationUrl}` + url} title={message}/>
                <FacebookMessengerShareButton children={<FacebookMessengerIcon round size={50}/>}
                                              onShareWindowClose={shareAndClose} url={"http://heduki.com" + url}
                                              appId={'1383062722208577'}/>
            </Stack>
        );
    }

    return (
        <Modal open={true} onClose={onClose}>
            <div className={ShareMatchModalStyles.Box} style={side === 1 ? {left: '25%'}: {left: '60%'}}>
                <div className={ShareMatchModalStyles.TextTitle}>
                    {Texts.ShareMatchModal.SHARE_MATCH_MODAL_SEND_CLIP_VIA}
                </div>
                <div>
                    {getShareButtons()}
                </div>
            </div>
        </Modal>
    );
}

export default ShareClipSocialsModal;