import React from "react";
import signUpStyles from './SignUpPage.module.css';
import Logo from '../../Assets/clipLogo-with_name.svg'

const SignUpHeader = () => {
    return (
        <div className={signUpStyles.Logo}>
            <img src={Logo} alt={"ClipLogo"}/>
        </div>
    );
};

export default SignUpHeader;