import React from "react";
import signUpStyles from './SignUpPage.module.css';
import {AppPaths, Texts} from "../../Utils/Texts";
import {generatePath, useNavigate} from "react-router-dom";

const HaveAccountRow = () => {

    const navigate =  useNavigate()

    const handleSignIn = () => {
        const path = generatePath(AppPaths.SignIn);
        navigate(path)
    }

    return (
        <div className={signUpStyles.RowSignIn}>
            <div className={signUpStyles.Text}>
                {Texts.SignUpPage.SIGN_UP_HAVE_ACCOUNT}
            </div>
            <div className={signUpStyles.GoToSignInButton}>
                <button onClick={handleSignIn} className={signUpStyles.TextOnlyButton}>
                    {Texts.SignUpPage.SIGN_UP_GO_TO_SIGN_IN}
                </button>
            </div>
        </div>
    );
};

export default HaveAccountRow;