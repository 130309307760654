import React, {useEffect, useState} from "react";
import {Modal} from "@mui/material";
import respondToClipStyle from './RespondToClipModal.module.css';
import {Texts} from "../../../Utils/Texts";
import AcceptButton from "../../../Assets/acceptButton.svg"
import RejectButton from "../../../Assets/rejectButton.svg"
import {Avatars} from "../Avatars/Avatars";
import {AcceptClip, GetClip, GetUserDetailsFromServer, RejectClip} from "../../../ClipClient/UserToServer";
import {useSelector} from "react-redux";
import {selectName, selectPicture, selectToken, selectUserId} from "../../../features/user";
import {selectIndex} from "../../../features/newClip";
import ErrorModal from "../ErrorModal/ErrorModal";
import Countdown from "react-countdown";

const RespondToClipModal = ({clipID, onClose}) => {
    const [showModal, setShowModal] = useState(true)
    const token = useSelector(selectToken)
    const userId = useSelector(selectUserId)
    const username = useSelector(selectName)
    const userPicture = useSelector(selectPicture)
    const index = useSelector(selectIndex)
    const [clipperName, setClipperName] = useState('')
    const [otherName, setOtherName] = useState('')
    const [clipCreationTime, setClipCreationTime] = useState('')
    const [isClipExpired, setIsClipExpired] = useState(true)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorBody, setErrorBody] = useState('')
    const [dataReady, setDataReady] = useState(false)

    useEffect(() => {
        GetClip(token, clipID).then(response => {
            if (response.status === 202 || response.status === 200) {
                response.json().then(res => {
                    if (res.responseCode === 'Success') {
                        checkClipTime();
                        setClipCreationTime(res.data.timeCreated);
                        index === '1' ? setOtherName(res.data.clippedBName) : setOtherName(res.data.clippedAName);
                        GetUserDetailsFromServer(token, res.data.clipperId).then(response => {
                            if (response.status === 202 || response.status === 200) {
                                response.json().then(res => {
                                    if (res.responseCode === 'Success') {
                                        setClipperName(res.data.name)
                                        setDataReady(true);
                                    } else {
                                        setErrorBody(res.message);
                                        setShowErrorModal(true)
                                    }
                                })
                            } else {
                                setErrorBody(response.message);
                                setShowErrorModal(true)
                            }
                        });
                    } else {
                        setErrorBody(res.message);
                        setShowErrorModal(true)
                    }
                });
            } else {
                response.json().then(res => {
                    setErrorBody(res.message);
                    setShowErrorModal(true)
                });
            }
        })
    })

    const checkClipTime = () => {
        if (new Date(clipCreationTime).addHours(48) < Date.now())
            setIsClipExpired(true);
        else
            setIsClipExpired(false);
    }

    const HideErrorModal = () =>
    {
        setShowErrorModal(false);
        setErrorBody('');
    }

    const onAcceptClip = () => {
        handleClose();
        AcceptClip(token, clipID, userId, index).then(response => {
            if (response.status === 202 || response.status === 200) {
                response.json().then(res => {
                    if (res.responseCode !== 'Success') {
                        setErrorBody(res.message);
                        setShowErrorModal(true)
                    }
                });
            } else {
                response.json().then(res => {
                    setErrorBody(res.message);
                    setShowErrorModal(true)
                });
            }
        })
    }

    const onRejectClip = () => {
        handleClose();
        RejectClip(token, clipID, userId, index).then(response => {
            if (response.status === 202 || response.status === 200) {
                response.json().then(res => {
                    if (res.responseCode !== 'Success') {
                        setErrorBody(res.message);
                        setShowErrorModal(true)
                    }
                });
            } else {
                response.json().then(res => {
                    setErrorBody(res.message);
                    setShowErrorModal(true)
                });
            }
        })
    }

    const handleClose = () => {
        setShowModal(false);
        onClose();
    }

    return (
        dataReady && <>
            {isClipExpired ?
                <Modal open={showModal}>
                    <div className={respondToClipStyle.Box}>
                        <div className={respondToClipStyle.TextTitle}>
                            {Texts.RespondToClip.RESPOND_TO_CLIP_TIME_OUT}
                        </div>
                        <button onClick={handleClose} className={respondToClipStyle.CloseButton}>
                            close
                        </button>
                    </div>
                </Modal> :
                <Modal open={showModal}>
                    <div className={respondToClipStyle.Box}>
                        <div className={respondToClipStyle.TextTitle}>
                            {Texts.RespondToClip.RESPOND_TO_CLIP_TITLE + clipperName + '!'}
                        </div>
                        <Avatars img1={userPicture} name1={username} name2={otherName}/>
                        <div className={respondToClipStyle.TextTitle}>
                            {username} & {otherName}
                        </div>
                        <div className={respondToClipStyle.TextBody}>
                            {'Hi'} {username + '!'} {Texts.RespondToClip.RESPOND_TO_CLIP_BODY}
                        </div>
                        <div className={respondToClipStyle.Counter}>
                            <p style={{paddingRight: '8px'}}>You have only</p>
                            <Countdown date={new Date(clipCreationTime).addHours(48)}/>
                            <p style={{paddingLeft: '8px'}}>time left</p>
                        </div>
                        <div className={respondToClipStyle.ButtonsContainer}>
                            <button onClick={onAcceptClip} className={respondToClipStyle.Button}>
                                <img src={AcceptButton} alt={'AcceptButtonImg'}/>
                            </button>
                            <button onClick={onRejectClip} className={respondToClipStyle.Button}>
                                <img src={RejectButton} alt={'RejectButtonImg'}/>
                            </button>
                        </div>
                    </div>
                </Modal>
            }
        <ErrorModal body={errorBody} showModal={showErrorModal} onClose={HideErrorModal}/>
        </>
    );
};

export default RespondToClipModal;