export const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !!(email && regex.test(email));
}

export const validatePassword = (password) => {
    if (password === undefined) {
        return false;
    }
    const nameWithoutWhiteSpaces = password.replace(/\s/g, "");
    return nameWithoutWhiteSpaces !== '';
}

export const validateName = (name) => {
    if (name === undefined) {
        return false;
    }
    const nameWithoutWhiteSpaces = name.replace(/\s/g, "");
    return nameWithoutWhiteSpaces !== '';
}

    function getDateTime(date) {
        if (!date) return '';
        date = date.replace(' ', 'T');
        const year = date.substr(0, 4);
        const month = date.substr(5, 2);
        const day = date.substr(8, 2);
        const hour = date.substr(11, 2);
        const minute = date.substr(14, 2);
        const second = date.substr(17, 2);
        const d = new Date(year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second);
        d.setHours(d.getHours());
        return d;
    }

export const daySinceSent = (date) => {
    if (!date) return '';
    return getDateTime(date).toString().substr(4, 6);
}

export const formattedDateAndTime = (date) => {
    if(!date) return '';
    try {
        const dateObj = new Date(date);
        return dateObj.toLocaleString()
    }
    catch (error){
        return error.toString()
    }
}


export const formattedDate = (date) => {
    if(!date) return '';
    try {
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString()
    }
    catch (error){
        return error.toString()
    }
}

export const getPictureUrl = (url) => {
    return url ? `data:image/jpg;base64,${url}`: ''
}