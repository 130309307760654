import React from "react";
import {Texts} from "../../Utils/Texts";
import MyChatsStyles from './MyChatsPage.module.css';

const ClipMessagesHeader = () => {

    return (
        <div className={MyChatsStyles.text}>
            {Texts.MyChatsPage.MY_CHATS_HEADER}
        </div>
    )
}

export default ClipMessagesHeader;