import React from "react";
import {Modal} from "@mui/material";
import clipStartedModalStyles from './ClipStartedModal.module.css';
import {Texts} from "../../../Utils/Texts";
import Confetti from '../../../Assets/confetti.svg'
import {Avatars} from "../Avatars/Avatars";
const ClipStartedModal = (props) => {

    const {onClose, nameA, nameB} = props;

    return (
        <Modal open={true} onClose={onClose}>
            <div className={clipStartedModalStyles.Box}>
                <div className={clipStartedModalStyles.TextTitle}>
                    {Texts.ClipStartedModal.CLIP_STARTED_MODAL_TITLE}
                </div>
                <div className={clipStartedModalStyles.Image}>
                    <img style={{zIndex:2, height:126, width:370}} src={Confetti} alt={'Confetti'}/>
                    <div style={{zIndex:1,marginTop:-90}}>
                        <Avatars name1={nameA} name2={nameB}/>
                    </div>
                </div>
                <div className={clipStartedModalStyles.TextTitle}>
                    {Texts.ClipStartedModal.CLIP_STARTED_MODAL_CLIPPED_TITLE} {nameA} & {nameB}
                </div>
                <div className={clipStartedModalStyles.TextBody}>
                    {Texts.ClipStartedModal.CLIP_STARTED_MODAL_BODY}
                </div>
                <div className={clipStartedModalStyles.Button}>
                    <button onClick={onClose} className={clipStartedModalStyles.Button}>
                        {Texts.ClipStartedModal.CLIP_STARTED_MODAL_BUTTON}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ClipStartedModal;