import React, {Fragment} from "react";
import signInStyles from './SingInPage.module.css';
import {Texts} from "../../Utils/Texts";
import clipLogo from '../../Assets/clipLogo-black.svg';

const SignInHeader = () => {
    return (
        <Fragment>
            <div className={signInStyles.Header}>
                <div className={signInStyles.Logo}>
                    <img src={clipLogo} alt={"ClipLogo"}/>
                </div>
                <div className={signInStyles.TextBox}>
                    <div className={signInStyles.Title}>
                        {Texts.SignInPage.SIGN_IN_TITLE}
                    </div>
                    <div className={signInStyles.Support}>
                        {Texts.SignInPage.SIGN_IN_SUPPORT_TEXT}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SignInHeader;