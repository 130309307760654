import React from "react";
import signUpStyles from './SignUpPage.module.css';
import Stars from '../../Assets/Stars.svg'
import {Texts} from "../../Utils/Texts";

const WelcomeSection = () => {
    return (
        <div className={signUpStyles.WelcomeSection}>
            <div className={signUpStyles.WelcomeText}>
                <div className={signUpStyles.Stars}>
                    <img src={Stars} alt={"Stars"}/>
                </div>
                <div className={signUpStyles.TextBox}>
                    <div className={signUpStyles.Title}>
                        {Texts.SignUpPage.SIGN_UP_WELCOME_TITLE}
                    </div>
                    <div className={signUpStyles.Support}>
                        {Texts.SignUpPage.SIGN_UP_SUPPORT_TEXT}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeSection;