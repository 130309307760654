import {createStore, applyMiddleware} from '@reduxjs/toolkit';
import userReducer from '../features/user'
import newClipReducer from '../features/newClip'
import {combineReducers} from '@reduxjs/toolkit'
import {persistStore, persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers(
    {user: userReducer, newClip: newClipReducer}
);

const persistConfig = {
  key: 'main-root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    applyMiddleware()
)

const storePersist = persistStore(store)

export {storePersist}
export default store