import {Box, IconButton, TextField} from "@mui/material";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, {useState} from "react";

const ShareClipMessageForm = (props) => {

    const {message, setMessage} = props;
    const [copied, setCopied] = useState(false)

    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
        setCopied(false)
    }

    return (
        <Box >
            <TextField
                variant={'outlined'}
                multiline={true}
                defaultValue={message}
                onChange={handleChangeMessage}
                rows= {10}
                style = {{width: 600}}
            />
            <CopyToClipboard text={message}>
                <IconButton onClick={() => setCopied(true)}>
                    <ContentCopyIcon color={copied ? 'success' : ''}/>
                </IconButton>
            </CopyToClipboard>
        </Box>
    );
}

export default ShareClipMessageForm;