import React from 'react';
import './App.css';
import ClipAppBar from "./app/ClipAppBar";
import Routing from "./Pages/Routing";

function App() {
    return (
    <div className="App">
        <ClipAppBar/>
        <Routing/>
    </div>
    );
}

export default App;
