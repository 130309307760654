import React from 'react';
import CreateAClipStyles from './CreateAClip.module.css';
import {Texts} from '../../Utils/Texts';
import {styled, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {ClipCategory} from '../../Utils/Types';

const StyledToggleButton = styled(ToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        borderRadius: 6,
    },
}));

const ToggleCategory = ({category, handleChangeCategory}) => {

    return (
        <div className={CreateAClipStyles.Container}>
            <ToggleButtonGroup
                exclusive={true}
                onChange={handleChangeCategory}
                value={category}
                className={CreateAClipStyles.Toggle}
                defaultValue={ClipCategory.BUSINESS}
                fullWidth={true}
            >
                <StyledToggleButton
                    value={ClipCategory.BUSINESS}
                    disableRipple={true}
                    style={{
                        textTransform: 'none',
                        borderWidth: 0,
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: "500",
                        fontSize: 14,
                        color: '#667085'
                    }}
                >
                    {Texts.CreateAClipPage.CREATE_A_CLIP_CATEGORIES_BUSINESS}
                </StyledToggleButton>
                <StyledToggleButton
                    value={ClipCategory.ROMANCE}
                    disableRipple={true}
                    style={{
                        textTransform: 'none',
                        borderWidth: 0,
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: "500",
                        fontSize: 14,
                        color: '#667085'
                    }}
                >
                    {Texts.CreateAClipPage.CREATE_A_CLIP_CATEGORIES_ROMANCE}
                </StyledToggleButton>
            </ToggleButtonGroup>
        </div>
    );
};

export default ToggleCategory;

