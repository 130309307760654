import {IconButton, List, ListItem, ListItemText, ListSubheader, Popover} from "@mui/material";
import * as React from "react";
import {NotificationsOutlined} from "@mui/icons-material";
import {GetNotifications} from "../ClipClient/UserToServer";
import {useSelector} from "react-redux";
import {selectToken} from "../features/user";
import GreenDot from  '../Assets/Dot.svg'
import {formattedDateAndTime} from "../Utils/Helpers";

export const NotificationList = ({notifications}) => {
    return (
        <List
            style={{width:500, height: 400}}
            subheader={<ListSubheader
                sx={{
                    fontWeight: 500,
                    fontFamily: 'Inter, sans-serif',
                    fontSize: 20,
                    textAlign: 'left',
                    color: '#101828'
                }}>Notifications</ListSubheader>}
        >
            {notifications.map((notification, index) =>
                (
                    <ListItem
                        divider={true}
                        style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}
                    >
                        <div style={{display:"flex", flexDirection:'column'}}>
                        <text>{formattedDateAndTime(notification.timeReceived)}</text>
                        <ListItemText
                            key={index}
                            sx={{
                                fontWeight: 400,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: 14,
                                textAlign: 'left',
                                color: " #667085"
                            }}
                            primary={notification.content}
                        />
                        </div>
                        {!notification.isObserved && <img src={GreenDot} alt={'greenDot'}/>}
                    </ListItem>
                ))
            }
            {notifications.length === 0 &&
                <ListItem>
                        <ListItemText
                            key={'noNotifications'}
                            sx={{
                                fontWeight: 400,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: 14,
                                textAlign: 'left',
                                color: " #667085"
                            }}
                            primary={'You don\'t have any notifications yet'}
                        />
                </ListItem>}
        </List>
    )
}

export const NotificationsPopover = () => {
    const [anchorElNotifications, setAnchorElNotifications] = React.useState(null);
    const [notificationList, setNotificationList] = React.useState([]);
    const token = useSelector(selectToken);

    const handleClickNotifications = (event) => {
        userNotifications()
        setAnchorElNotifications(event.currentTarget);
    };

    const handleCloseNotifications = () => {
        setAnchorElNotifications(null);
    };

    const openNotifications = Boolean(anchorElNotifications);
    const idNotifications = openNotifications ? 'notifications-popover' : undefined;


    const userNotifications = () => {
        GetNotifications(token).then(response => {
            if (response.status === 202 || response.status === 200) {
                response.json().then(res => {
                    if (res.responseCode === 'Success') {
                        let notifications = res.data.map((notification) => notification)
                        setNotificationList(notifications.reverse())
                    }
                })
            }
        });
    }

    return (
        <div>
            <IconButton aria-describedby={idNotifications} onClick={handleClickNotifications}>
                <NotificationsOutlined/>
            </IconButton>
            <Popover
                id={idNotifications}
                open={openNotifications}
                anchorEl={anchorElNotifications}
                onClose={handleCloseNotifications}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                marginThreshold={50}
            >
                <NotificationList notifications={notificationList}/>
            </Popover>
        </div>
    )
}