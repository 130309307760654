import React, {useEffect} from "react";
import SignInHeader from "./SignInHeader";
import SignInForm from "./SignInForm";
import CreateAccountRow from "./CreateAccountRow";
import signInStyles from './SingInPage.module.css';
import {useParams} from "react-router-dom";
import {addClip} from "../../features/newClip";
import {useDispatch} from "react-redux";


const SignInPage = () => {
    const {clipID, index} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (clipID && index) {
            if (index === '1' || index === '2') {
                dispatch(addClip({clipID: clipID, index: index}))
            }
        }
    }, [clipID, index]);

    return (
        <div className={signInStyles.SignIn}>
            <div className={signInStyles.Content}>
                <SignInHeader/>
                <SignInForm/>
                <CreateAccountRow/>
            </div>
        </div>
    );
};

export default SignInPage;