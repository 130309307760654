import React from 'react';
import CreateAClipStyles from './CreateAClip.module.css';
import {Texts} from "../../Utils/Texts"
import {useSelector} from 'react-redux'
import {selectName} from "../../features/user";

const CreateAClipHeader = () => {
    const username = useSelector(selectName);
    return (
        <div className={CreateAClipStyles.Header}>
            <div className={CreateAClipStyles.Title}>
                {Texts.CreateAClipPage.CREATE_A_CLIP_TITLE_WITHOUT_NAME + `${username}`}
            </div>
            <div className={CreateAClipStyles.Description}>
                {Texts.CreateAClipPage.CREATE_A_CLIP_DESCRIPTION}
            </div>
        </div>
    );
};

export default CreateAClipHeader;