import React from "react";
import MyClipsStyles from './MyClipsPage.module.css';
import {Texts} from "../../Utils/Texts";

const MyClipsHeader = () => {

    return (
        <div className={MyClipsStyles.Header}>
            <div className={MyClipsStyles.Title}>
                {Texts.MyClipsPage.MY_CLIPS_TITLE}
            </div>
            <div className={MyClipsStyles.Subtitle}>
                {Texts.MyClipsPage.MY_CLIPS_SUBTITLE}
            </div>
        </div>
    );
}

export default MyClipsHeader;