import React from "react";
import {Modal} from "@mui/material";
import ErrorModalStyle from './ErrorModal.module.css';

const RespondToClipModal = ({showModal,onClose, body}) => {

    return (
        <Modal open={showModal}>
            <div className={ErrorModalStyle.Box}>
                <div className={ErrorModalStyle.TextTitle}>
                    {'Error'}
                </div>
                <div className={ErrorModalStyle.TextBody}>
                    {body}
                </div>
                <div className={ErrorModalStyle.ButtonsContainer}>
                    <button className={ErrorModalStyle.Button} onClick={onClose}>
                        <div className={ErrorModalStyle.ButtonText}>
                            {'CLOSE'}
                        </div>
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default RespondToClipModal;