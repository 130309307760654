import React from "react";
import MyClipsStyles from './MyClipsPage.module.css';
import MyClipsHeader from "./MyClipsHeader";
import MyClipsTable from "./MyClipsTable";

const MyClipsPage = () => {

    return (
        <div className={MyClipsStyles.MainContainer}>
            <MyClipsHeader />
            <MyClipsTable />
        </div>
    );
}

export default MyClipsPage;