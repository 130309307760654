import * as React from "react";
import statusStyle from './status.module.css'

export const Status = ({status}) => {

    const getStyle = (status) => {
        if (status==='Pending')
        {
           return statusStyle.Pending
        }
        if (status === 'Rejected')
        {
            return statusStyle.Rejected
        }
        if (status === 'Approved')
        {
            return statusStyle.Accepted
        }
        if (status === 'Canceled')
        {
            return statusStyle.Rejected
        }
    }
    return(
        <text className={getStyle(status)}>{status}</text>
    )
}