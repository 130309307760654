import React from "react";
import signInStyles from './SingInPage.module.css';
import {AppPaths, Texts} from "../../Utils/Texts";
import {generatePath, useNavigate} from "react-router-dom";

const CreateAccountRow = () => {

    const navigate =  useNavigate()

    const handleSignUp = () => {
        const path = generatePath(AppPaths.SignUp);
        navigate(path)
    }

    return (
      <div className={signInStyles.RowSignUp}>
          <div className={signInStyles.Text}>
              {Texts.SignInPage.SIGN_IN_DONT_HAVE_ACCOUNT}
          </div>
          <div className={signInStyles.GoToSignUpButton}>
              <button onClick={handleSignUp} className={signInStyles.TextOnlyButton}>
                  {Texts.SignInPage.SIGN_IN_GO_TO_SIGN_UP}
              </button>
          </div>
      </div>
    );
};

export default CreateAccountRow;