import React from 'react';
import CreateAClipStyle from './CreateAClip.module.css';
import {Texts} from '../../Utils/Texts';
import businessIcon from '../../Assets/buisinessCategoryIcon.svg'
import romanticIcon from '../../Assets/romanticCategoryIcon.svg'
import {ClipCategory} from '../../Utils/Types';
import ClipInput from '../../Components/ClipInput/ClipInput';

const NamesForm = ({category, name1, name2, handleChangeName1, handleChangeName2, name1Error, name2Error}) => {
    return (
        <div className={CreateAClipStyle.Container}>
            <div className={CreateAClipStyle.NameForm}>
                <div className={CreateAClipStyle.InputContainer}>
                    <ClipInput
                        title={Texts.CreateAClipPage.CREATE_A_CLIP_NAME_TITLE}
                        value={name1}
                        setValue={handleChangeName1}
                        error={name1Error}
                        errorMsg={Texts.CreateAClipPage.ERROR_MSG_NAME_FORM}
                    />
                </div>
                <img src={category === ClipCategory.BUSINESS ? businessIcon : romanticIcon} alt={"categoryIcon"}/>
                <div className={CreateAClipStyle.InputContainer}>
                    <ClipInput
                        title={Texts.CreateAClipPage.CREATE_A_CLIP_NAME_TITLE}
                        value={name2}
                        setValue={handleChangeName2}
                        error={name2Error}
                        errorMsg={Texts.CreateAClipPage.ERROR_MSG_NAME_FORM}
                    />
                </div>
            </div>
        </div>
    );
};

export default NamesForm;

