import React from "react";
import ClipsByMeStyles from './ClipsByMePage.module.css';
import {Texts} from "../../Utils/Texts";

const ClipsByMeHeader = () => {

    return (
        <div className={ClipsByMeStyles.Header}>
            <div className={ClipsByMeStyles.Title}>
                {Texts.ClipsByMePage.CLIPS_BY_ME_TITLE}
            </div>
            <div className={ClipsByMeStyles.Subtitle}>
                {Texts.ClipsByMePage.CLIPS_BY_ME_SUBTITLE}
            </div>
        </div>
    );
}

export default ClipsByMeHeader;