import {Button, List, ListItem, ListItemButton, Popover} from "@mui/material";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {createUser, selectPicture} from "../features/user";
import {generatePath} from "react-router";
import {AppPaths, Texts} from "../Utils/Texts";
import UserProfileModal from "./Modal/UserProfileModal/UserProfileModal";
import {useState} from "react";
import {SingleAvatar} from "./Modal/Avatars/Avatars";

export const UserPopover = ({username}) => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [showUserProfile, setShowUserProfile] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userPicture = useSelector(selectPicture);

    const handleClickUser = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUser = () => {
        setAnchorElUser(null);
    };

    const openUser = Boolean(anchorElUser);
    const idUser = openUser ? 'user-popover' : undefined;


    const onLogout = () => {
        dispatch(createUser({name: '', token: '', userId: ''}));
        navigate(generatePath(AppPaths.SignIn))
    }


    return(
        <div>
            <Button
                startIcon={<SingleAvatar img={userPicture} name={username}/>}
                sx={{
                    fontFamily: 'Inter, sans-serif',
                    fontStyle: 'normal',
                    fontSize: 18,
                    color: '#414141',
                    backgroundColor: 'none',
                    textTransform: 'none',
                }}
                aria-describedby={idUser}
                onClick={handleClickUser}
            >
                {username}
            </Button>
            <Popover
                id={idUser}
                open={openUser}
                anchorEl={anchorElUser}
                onClose={handleCloseUser}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                marginThreshold={50}
            >
                <List style={{}}>
                    <ListItem>
                        <ListItemButton
                            key={'profile'}
                            sx={{fontWeight: 400, fontFamily: 'Inter, sans-serif', fontSize: 14, textAlign: 'left', color:" #667085"}}
                            divider={true}
                            onClick={()=>setShowUserProfile(true)}
                        >
                            {Texts.AppBar.APP_BAR_USER_VIEW_PROFFILE}
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            key={'logout'}
                            sx={{fontWeight: 400, fontFamily: 'Inter, sans-serif', fontSize: 14, textAlign: 'left', color:" #667085"}}
                            onClick={onLogout}
                        >
                            {Texts.AppBar.APP_BAR_USER_LOG_OUT}
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>
            <UserProfileModal showModal={showUserProfile} onClose={()=>setShowUserProfile(false)}/>
        </div>
    )
}